.localVideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.localVideoContainer.waiting.desktop {
  width: 540px;
  height: 460px;
  margin: auto;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.topPadding.desktop {
  padding-top: calc(50vh - 230px);
}

.localVideo.waiting.desktop {
  position: relative;
  width: 500px;
  height: 300px;
  border-radius: 5px;
}

.localVideoContainer.waiting.desktop .startButton {
  position: relative;
  width: 100%;
  margin: 0px;
  margin-top: 10px;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.localVideoContainer.waiting.desktop .peerInfo {
  position: relative;
  width: 100%;
  margin: 0px;
  left: 0px;
  background-color: transparent;
  color: #000;
}

.volumeIndicatorContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}
.volumeIndicatorContainer.desktop {
  position: relative;
  top: auto;
  right: auto;
  margin-bottom: -40px;
  margin-left: 10px;
  z-index: 99999;
}
.volumeIndicatorContainer.desktop.connected {
  position: absolute;
  bottom: 80px;
  left: 30px;
  z-index: 99999;
}

.localVideo.connected {
  z-index: 888;
  object-fit: cover;
  width: 30vw;
  height: 30vh;
  position: absolute;
  top: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.localVideo.connected.desktop {
  bottom: 30px;
  left: 30px;
}

.peerInfo {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background: #9f59b3;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.remoteVideo {
  pointer-events: none;
  object-fit: cover;
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.multiPeer .peerName {
  background: #9f59b3;
  border-radius: 5px;
  padding: 20px;
  z-index: 9999;
  position: relative;
  top: auto;
  left: auto;
  width: auto;
  text-align: center;
  padding: 10px;
}

.peerName {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background: #9f59b3;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.multiPeer .remoteVideoContainer {
  flex-direction: column;
}

.peersContainer.multiPeer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.multiPeer .remoteVideo.connected {
  width: auto;
  height: auto;
  position: relative;
  top: auto;
  left: auto;
  display: block;
}
.remoteVideo.connected {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.startButton {
  z-index: 999;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  width: calc(100% - 40px);
  height: 40px;
  border-radius: 10px;
  border-width: 0px;
  background: #9f59b3;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Signika", sans-serif;
  cursor: pointer;
}

.infoWindow {
  z-index: 999;
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  bottom: 0px;
  top: 0px;
  border-width: 0px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 22px;
  line-height: 100vh;
  font-weight: bold;
  font-family: "Signika", sans-serif;
}

.trackError {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  background: rgb(255, 240, 240);
}

.trackError svg {
  float: left;
  width: 15px;
  height: 15px;
  margin-right: 20px;
}

.deviceInfo {
  clear: both;
  margin-top: 15px;
}

.device {
  clear: both;
  padding: 0px 0px 10px 0px;
}

.device span,
.device button {
  font-size: 14px !important;
}
.device svg {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.disconnected svg {
  fill: rgba(0, 0, 0, 0.2);
}

.textButton {
  border: 0px;
  background-color: transparent;
  color: #9f59b3;
  cursor: pointer;
}

.controlsContainer {
  z-index: 999999;
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 100%;
  color: #fff;
}

.controlButton {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  float: right;
  margin-right: 10px;
  background: #9f59b3;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.controlButton.hangUpButton {
  width: 80px;
  border-radius: 30px;
  background-color: rgb(255, 71, 71);
}

.controlButton.hangUpButton svg {
  width: 40px;
  height: 40px;
  margin-top: 5px;
}

.controlButton.disabled {
  background-color: #666;
}
.controlButton.disabled svg {
  fill: #ddd;
}

.controlsContainer.desktop {
  bottom: 15px;
}

.controlsContainer.desktop .controlButton {
  margin-right: 15px;
}

.controlButton svg {
  fill: #fff;
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
.controlButton svg {
  fill: #fff;
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
