.home {
  max-width: 800px;
  padding: 20px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  margin: auto;
  max-width: 150px;
  margin-bottom: 20px;
}

.noBookings {
  padding: 20px;
  background: rgb(255, 250, 230);
  border: 2px solid rgb(255, 243, 189);
}

.booking {
  padding: 20px;
}
.booking a {
  font-size: 22px;
}
