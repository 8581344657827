a {
  color: #9f59b3;
}
body {
  margin: 0;
  font-family: sans-serif;
  background: #222;
  min-width: 100vw;
  min-height: 100vh;
}

#root {
  min-width: 100vw;
  min-height: 100vh;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

p {
  line-height: 1.7em;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Signika", sans-serif;
  margin: 0px;
}

h1 {
  font-size: 32px;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 20px;
}
* {
  font-size: 14px;
}

@media only screen and (min-width: 1000px) {
  h1 {
    font-size: 52px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 25px;
  }
  * {
    font-size: 15px;
  }
}
