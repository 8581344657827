.errorContainer {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  background: rgb(255, 240, 240);
}

.errorMessage svg {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.errorMessage h3 {
  margin-bottom: 20px;
}

.errorMessage div,
.errorMessage a {
  display: block;
  margin-bottom: 10px;
}
